<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <search :query.sync="query" @search="search" />
        <el-table
          ref="table"
          id="listModels"
          class="list"
          :data="items"
        >
          <el-table-column prop="name" label="Название">
            <template slot-scope="scope">
              <router-link class="link" :to="{ name: 'models:edit', params: { id: scope.row.id_model } }">
                {{ scope.row.name }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="main_stream_resolution" label="Разрешение MAIN" />
          <el-table-column prop="sub_stream_resolution" label="Разрешение SUB" />
          <el-table-column prop="transcode_video" label="Транскодинг видео">
            <template slot-scope="scope">
              <el-checkbox :value="Boolean(scope.row.transcode_video)" />
            </template>
          </el-table-column>
          <el-table-column prop="transcode_audio" label="Транскодинг аудио">
            <template slot-scope="scope">
              <el-checkbox :value="Boolean(scope.row.transcode_audio)" />
            </template>
          </el-table-column>
          <el-table-column prop="is_onvif" label="ONVIF">
            <template slot-scope="scope">
              <el-checkbox :value="Boolean(scope.row.is_onvif)" />
            </template>
          </el-table-column>
          <el-table-column prop="is_ptz" label="PTZ">
            <template slot-scope="scope">
              <el-checkbox :value="Boolean(scope.row.is_ptz)" />
            </template>
          </el-table-column>
          <el-table-column label="Действия">
            <template slot-scope="scope">
              <el-button
                size="small"
                type="danger"
                @click="remove(scope.row.id_model, scope.row.name)"
              >Удалить
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="list__pagination"
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          :current-page="page"
          @current-change="changePage"
        />
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button
        class="page__active_button"
        icon="el-icon-plus"
        type="primary"
        @click="$router.push({ name: 'models:create' })"
      >Добавить
      </el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import routeList from "@/core/mixins/routeList";
import Search from "@/components/Search";

export default {
  name: "models-list",
  components: {
    Search,
  },
  mixins: [routeList],
  computed: {
    ...mapState("models", {
      items: (state) => state.models.data,
      total: (state) => state.models.total,
      count: (state) => state.models.count,
    }),
    ...mapGetters("models", ["getQuery"]),
  },
  data () {
    return {
      query: "",
      activeName: "list",
    }
  },
  created () {
    this.clearList({
      data: [],
      total: 0,
      count: 50
    })
  },
  methods: {
    remove(id, name) {
      this.$confirm(`Удалить модель ${name}?`, 'Удаление', {
        confirmButtonText: 'Удалить',
        type: 'warning'
      })
        .then(() => this.removeModel(id))
        .then(() => this.getList({
          query: this.query,
          page: this.page,
          count: this.count
        }))
    },
    ...mapActions('models', {
      getList: 'GET_MODELS',
      removeModel: 'REMOVE_MODEL',
    }),
    ...mapMutations('models', {
      setQuery: 'SET_QUERY',
      clearList: 'SET_LIST_MODELS',
    })
  }
}
</script>

<style></style>
